/* CSS for scroll snapping functionality */
html {
    scroll-behavior: smooth;
}

/* Enable scroll snap on the main container */
.scroll-snap-container {

    overflow-x: hidden;
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}

/* Make each section a snap point */
.scroll-snap-section {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Disable scroll snap on mobile for better UX */
@media (max-width: 768px) {
    .scroll-snap-container {
        scroll-snap-type: none;
    }

    .scroll-snap-section {
        scroll-snap-align: none;
        min-height: auto;
    }
}

/* Smooth scrolling for all browsers */
html {
    scroll-behavior: smooth;
}

/* For browsers that don't support scroll-behavior */
@supports not (scroll-behavior: smooth) {
    html {
        overflow-x: hidden;
        overflow-y: auto;
    }

    body {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

/* Add transition effect when scrolling between sections */
.scroll-transition {
    transition: transform 0.8s cubic-bezier(0.65, 0, 0.35, 1);
}

/* Custom scrollbar styling */
.scroll-snap-container::-webkit-scrollbar {
    width: 8px;
}

.scroll-snap-container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
}

.scroll-snap-container::-webkit-scrollbar-thumb {
    background-color: rgba(126, 34, 206, 0.5);
    border-radius: 20px;
}

.scroll-snap-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(126, 34, 206, 0.8);
}